import { toArray } from "gsap";
import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

if(typeof gsap !== 'undefined') {


    // Set GSAP default vars
    gsap.defaults({
        ease: "power3.easeInOut"
    })

    // Set Scrolltrigger defaults
    ScrollTrigger.defaults({
        scrub:true
    })


    const scrollContainer = Array.from(document.querySelectorAll('.sk_parallax_bg_wrapper'))

    /**
     * Scroll Effect for block "sk-parallax-background"
     */

    scrollContainer.forEach((el, key, arr) => {
        if(key < 1) {
            let scrollStart = 'top middle'
            gsap.fromTo(
                '#' + el.id.toString() + ' .sk_parallax_bg_el',
                {
                    y: 0
                },
                {
                    yPercent: 33,
                    scrollTrigger: {
                        trigger: '.wp-block-sk-pharma-sk-parallax-background:has(#' + el.id.toString() + ')',
                        start: scrollStart,
                    }
                }
            )
        } else {
            let scrollStart = 'middle 30%'
            gsap.fromTo(
                '#' + el.id.toString() + ' .sk_parallax_bg_el:nth-child(1)',
                {
                    y: "0px",
                    ease: "expo.in"
                },
                {
                    yPercent: Math.floor(Math.random() * 48),
                    scrollTrigger: {
                        trigger: '.wp-block-sk-pharma-sk-parallax-background:has(#' + el.id.toString() + ')',
                        start: scrollStart
                    }
                }
            )
        
            gsap.fromTo(
                '#' + el.id.toString() + ' .sk_parallax_bg_el:nth-child(2)',
                {
                    y: "0px",
                    ease: "expo.in"
                },
                {
                    yPercent: Math.floor(Math.random() * 37),
                    scrollTrigger: {
                        trigger: '.wp-block-sk-pharma-sk-parallax-background:has(#' + el.id.toString() + ')',
                        start: scrollStart
                    }
                }
            )
        
            gsap.fromTo(
                '#' + el.id.toString() + ' .sk_parallax_bg_el:nth-child(3)',
                {
                    y: "0px",
                    ease: "expo.in"
                },
                {
                    yPercent: Math.floor(Math.random() * 67),
                    scrollTrigger: {
                        trigger: '.wp-block-sk-pharma-sk-parallax-background:has(#' + el.id.toString() + ')',
                        start: scrollStart
                    }
                }
            )
        }
        
    });
}